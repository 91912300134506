.socialIcons .flickr:before {
  content: "\f16e";
}

body:not(.subsite):not(.bdes) {
  .mainCallToAction {
    .bdes:not([class*="donate"]) {
      display: none;
    }
  }
}

@media (min-width: 1201px) {
    .menuMain ul.subMenu {
        z-index: 500; // To ensure it goes above listing page banner images
    }
}

@media (min-width: $nav-breakpoint) {

  .menuMain, .menuSub {
    border-bottom: 2px solid $brand-yellow;
  }

}

// .headerWrapper .carousel {
//   padding-left: 0;
//   padding-right: 0;
// }

@media (min-width: $carousel-breakpoint) {

  body.homepage,
  body.listing-with-feature {
    .carouselSlideTitle {
      width: 75%;
      justify-self: flex-end;
    }
  }

}

.carouselSlideDetail {
  border-left: 2px solid $brand-yellow;
}

@media (max-width: $nav-breakpoint) {
  .carousel {
    margin-top: 20px;
  }
}
// @media (min-width: $carousel-breakpoint) {

//   body.homepage,
//   body.listing-with-feature {
//     .carouselSlideTitle {
//       width: 75%;
//       justify-self: flex-end;
//     }
//   }

// }



.pageFooter {
  display: flex; // Now it won't behave as Fresco designs it
  flex-wrap: wrap;
}

.footerBox1, .footerBox2, .footerBox3 {
  margin: 0!important;
  .card-deck {
    margin-bottom: 0;
    .card {
      margin-bottom: 0;
    }
  }
  .text-over-image { /* update text over image template so it takes affect on mobile too */
      position: relative;
      padding: 0;
      .image-wrapper {
        background-color: black;
      }
      img.mediaImage {
        opacity: .5;
        width: 100%;
      }
      .text-wrapper {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 85%;
        max-width: 750px;
        color: white;
        padding: 0;
        h2 {
          margin: 0;
        }
      }
      &.light-overlay {
        .image-wrapper {
          background-color: white;
        }
        .text-wrapper {
          color: black;
        }
      }
  }
}

.footerBox6 {
  text-align: center;
  p {
    color: #6e6b67;
    margin-top: 10px;
  }
}

@media (min-width: 768px) {
  .footerBox1, .footerBox2, .footerBox3 {
    width: 33.33%;
    .card-deck {
      margin-bottom: 0;
    }
  }
  .footerBox4, .footerBox5 {
    width: 50%;
    margin: 60px 0 0 0!important;
  }
  .footerBox4 {
    border-right: 1px solid white;
    padding-right: 80px;
    text-align: right;
  }
  .footerBox5 {
    padding-left: 80px;
  }
  .footerBox6 {
    max-width: $container-max-width;
    width: 100%;
    margin: 40px auto 0!important;
    text-align: center;
    ul li {
      display: inline-block;
      padding: 10px;
    }
  }
}

@media (max-width: 768px) {
  .footerBox {
    width: 100%;
  }
  .footerBox4 {
    text-align: center;
  }
  .footerBox5 {
    text-align: center;
  }
}

// homepage

@media (min-width: $nav-breakpoint) {

  .homeIntro {
    padding-left: 320px;
  }

}

.homeFeaturesWrapper {
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100px;
    background-color: #fff;
  }
}

.homeFeedsWrapper {
  background-color: $brand-taupe;
}

// Change home page feeds (not on subsite) just on desktop

@media (min-width: 1024px) {
  .homeFeedBox1And2Wrapper {
    margin: auto;
    display: flex;
    flex-direction: row;
    max-width: $container-max-width;
    .homeFeedBox1,
    .homeFeedBox2 {
      width: 50%;
      .feedList {
        .feedItem {
          @media (max-width: 1280px) {
            min-width: 225px;
          }
          margin: 10px;
        }
      }
      h3.feedTitle {
        font-size: 1.25em;
      }
      li.feedItem:nth-child(2),
      li.feedItem:nth-child(3){
        .banner {
          display: none;
        }
        h3.feedTitle {
          margin-top: 20px;
        }
      }
    }
  }
}

.homeFeedblogs .feedItem [class*="DetailsWrapper"]:not(.publishDetailsWrapper) {
  max-width: 100%!important;
}

// Listings

.listing > .contentBlockWrapper {
    background-color: #f0efe6;
    max-width: 100%!important;
    position: relative;
    margin-top: 20px;
    margin-bottom: 0;
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 50px;
      background-color: #fff;
    }
    > .contentBlock {
    max-width: 1190px;
    margin: 0 auto;
    }
}

section.categoryFilterWrapper {
    z-index: 80;
    position: relative;
}

.categoryFilterContent {
    background-color: $brand-taupe;
}

.listFooter {
  margin-bottom: 5rem;
}

/* Cards */

@mixin yellow {
  position: relative;
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 100%;
    background-color: #ffb914;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    z-index: 0;
  }
  > * {
    z-index: 1;
    position: relative;
  }
}
@mixin yellowHover {
  &:after {
    height: 100%;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
  }
}

.listing .listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
  .listedPostText {
    @include yellow();
  }
  &:hover .listedPostText {
    @include yellowHover();
  }
}

.homeFeature {
  .homeFeatureDetailsWrapper {
    @include yellow();
  }
  &:hover .homeFeatureDetailsWrapper {
    @include yellowHover();
  }
}

.homeFeed:not(.homeFeedblogs) .feedItem {
  .feedItemDetailsWrapper {
    @include yellow();
  }
  &:hover .feedItemDetailsWrapper {
    @include yellowHover();
  }
}

// Posts

.breadcrumbWrapper {
  font-size: .9em;
}

body:not(.page-has-video-banner) {
  .post:not(.formWrapper) {
    .headerWrapper {
      padding: 0;
      max-width: $container-max-width;
      margin: auto;
    }
    .headerText {
        background-color: rgba(240, 239, 230, 0.9);
        padding-top: 2rem;
        padding-bottom: 2rem;
        border-left: 3px solid rgb(255, 185, 20);   
        margin: 0 20px 1.5rem; 
    }
    h1.title {
      margin-bottom: 0;
    }
  }
}

@media (min-width: 1024px) {

  body:not(.page-has-video-banner) {
    .post:not(.formWrapper) {
      .headerText {   
          margin-left: 25%;
      }
      .postContent {
          width: calc(75%);
      }
      .appealActions, .contentBlock .postAside {
        padding: 0 50px 0 0;
        width: 25%;
        min-width: 25%;
      }
      > .contentBlockWrapper:not(.accordianBody) > .contentBlock {
        display: flex;
        flex-direction: row-reverse;
      }
    }
    &.page-has-banner {   
      .post:not(.formWrapper) {
        .headerWrapper {
          position: relative;
          max-width: $container-max-width;
          margin: auto;
        }
        .carousel {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            margin-bottom: 40px;
        }
        .headerText {
            width: 40%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            margin: 0;
            left: 20px;
            h1.title {
              margin-bottom: 0;
            }
            .breadcrumbWrapper {
              font-size: .9em;
            }
            .breadcrumbList {
              margin-top: 0;
            }
        }
        ul.carouselSlides {
            width: 75%;
        }
        > .contentBlockWrapper:not(.accordianBody) > .contentBlock {
          display: flex;
        }
      }
    }
  }

}

// Listings

@media (min-width: 1024px) {

  .listing {
    .headerWrapper {
      max-width: $container-max-width;
      margin: auto;
    }
    .headerText {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      > *:not(.subsiteTitle) {
        width: 75%;
        margin-left: 25%;
      }
    }

  }

}

// Faqs 

body.faqs-list {

  .breadcrumbWrapper {
    display: none;
  }

}

.faqListingPage .contentBlock .listContent .listedFaq {
    background: white;
    margin-bottom: 2rem;
    @media (min-width: 1024px) {
      margin-left: 25%;
    }
}

header.accordianHeader.faqQuestion {
    margin-bottom: 0;
}

.accordianBody .contentBlock {
  padding: 40px;
  margin: 0;
}

.accordianHeader:hover {
  background-color: darken($brand-yellow, 5%);
}

// Subsites

body.subsite:not(.homepage) .headerWrapperSubsite {
  display: none;
}

/* Overriding a Fresco thing that disables banner videos - no idea why it's there */
.carouselSubsite .carouselSlideTitle {
  pointer-events: unset;
}


